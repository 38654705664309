import Bugsnag from '@bugsnag/js'
import { v4 as uuidV4 } from 'uuid'
import { getTabId } from 'util/tabId'
import { getVersion } from 'util/version'
import { uniq } from 'util/arrays'
import { LOGOUT_PAGE } from 'constants/pages'
import { MAINTENANCE_PAGE } from 'subapps/maintenance/pages'
import storage from 'util/storage'
import { doRefreshIfNecessary } from 'ducks/tokens/actions'
import { selectIsRefreshingToken } from 'selectors/app'
import doRedirectToBilling from 'subapps/settings/actions/doRedirectToBilling'
import config from 'config'

// maps the special snowflake payloads that GQL spits at us:
//
//  data: {...},
//  errors:[{
//      message :"{"message":"Request http://api.groovehq.docker/v1/template_categories?page=1 returned 401","status":401}"
//      originalError:"{"message":"Request http://api.groovehq.docker/v1/template_categories?page=1 returned 401","status":401}"
//    }]
//
// into something more sane...
//
//  data: {...},
//  errors:[{
//      message: "Request http://api.groovehq.docker/v1/template_categories?page=1 returned 401",
//      status: '401',
//      originalError:"{"message":"Request http://api.groovehq.docker/v1/template_categories?page=1 returned 401","status":401}"
//   }]
//
// Just to be even more special, GQL will sometimes also spit this format of error at us;
//
// {
//   "errors": [
//     {
//       "message": "Syntax Error GraphQL request (3:20) Unexpected Name \"null\"\n\n2:       query TicketQuery {\n3:         ticket(id: null) {\n                      ^\n4:           \n"
//     }
//   ]
// }

const mapErrors = (json, mode) => {
  return {
    ...json,
    errors: json.errors.map(error => {
      if (mode === 'gqlv2') return error

      const { originalError, message: jsonMessage } = error
      let message = null
      let status = null
      let meta = {}
      try {
        const parsed = JSON.parse(jsonMessage)
        message = parsed.message
        status = parsed.status
        meta = parsed.meta || {}
      } catch (parsingError) {
        // eslint-disable-next-line no-console
        console.error(parsingError)
        message = jsonMessage
        status = 400 // assume its a GQL syntax error
      }

      return {
        originalError,
        message,
        status,
        meta,
      }
    }),
  }
}

function isRefreshing() {
  return selectIsRefreshingToken(app.store.getState())
}

async function refreshIfNecessary() {
  return app.store.dispatch(doRefreshIfNecessary())
}

// Returns a rejected promise on network errors,
// resolves otherwise (caller should inspect response status/payload for
// 40x/50x errors etc)
const post = async (url, headers, body) => {
  const request = new Request(url, {
    method: 'POST',
    body,
    mode: 'cors',
    redirect: 'follow',
    headers: new Headers(headers),
    credentials: 'include',
  })
  const start = new Date().getTime()

  const response = await fetch(request)

  let parsed = null
  let runtime
  let latency
  try {
    parsed = await response.json()
    runtime = response.headers.get('x-runtime')
    if (runtime) {
      const elapsed = new Date().getTime() - start
      runtime = parseInt(runtime, 10)
      latency = elapsed - runtime
    }
  } catch (e) {
    runtime = null
    latency = null
  }
  return { runtime, latency, json: parsed || null, status: response?.status }
}

// xhr POST request with a linear backoff for failed network requests. Given
// options = { retries: 3, retryDelay: 2000 } then it will retry the request
// in 2s, 4s, 6s. Defaults to 3 retries, at a 1s, 2s and 3s interval.
async function postWithRetry(url, headers, body, retries = 3) {
  const retryDelay = 1000

  await refreshIfNecessary()

  return new Promise((resolve, reject) => {
    const wrappedPost = n => {
      // If we are currently refreshing the access token, then we want to
      // reschedule this request for later. This is to prevent race conditions,
      // since as soon as a refreshed access token is used then the previous one
      // becomes invalid.
      const { token } = storage.get('auth') || {}
      const headersWithToken = {
        ...headers,
        Authorization: `Bearer ${token}`,
      }
      if (isRefreshing()) {
        setTimeout(() => {
          wrappedPost(n)
        }, retryDelay)
        return
      }
      post(url, headersWithToken, body)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if (n <= retries) {
            setTimeout(() => wrappedPost(n + 1), retryDelay * n) // linear
          } else {
            reject(error)
          }
        })
    }
    wrappedPost(1)
  })
}

function getQueryName(query) {
  const stripped = query.replace(/^\s+|\s+$/g, '') // trim
  const match = stripped.match(/^(\w+\s\w+)/)
  if (!match) return stripped.slice(0, 32)
  return match[0]
}

// If the response json has errors (or is null), map to a rejected promise.
const mapGraphQLResponse = (
  url,
  headers,
  body,
  query,
  variables,
  requestId
) => response =>
  new Promise((resolve, reject) => {
    let doNotReport = false
    const queryName = getQueryName(query)
    const errorName = `GraphQL ${queryName} call failed`
    const isGQLV2 = url === config.app_graphql_url
    const { json, status } = response
    let error
    if (json && json.errors) {
      error = mapErrors(json, isGQLV2 ? 'gqlv2' : 'default') // returns something our callers can consume
      error.name = `Unhandled: ${errorName}`
      error.message = errorName
      error.requestId = requestId
    } else if (isGQLV2 && status === 401) {
      app.store.dispatch({ type: LOGOUT_PAGE })
    } else if (!json) {
      // Jank. For some reason not all GQL responses have a json payload.
      error = new Error('500: Request failed (no json)')
    }

    if (error) {
      if (
        error?.errors &&
        (error.errors.every(err => err.status === 404) ||
          error.errors.every(err => err.status === 422) ||
          error.errors.every(
            err => err.extensions?.code === 'RESOURCE_MERGED'
          ) ||
          error.errors.every(
            err => err.extensions?.code === 'RESOURCE_DELETED'
          ) ||
          error.errors.every(err => err.extensions?.code === 'UNAUTHORIZED'))
      ) {
        // There are all considered "handled" errors. This means we dont want to report it to bugsnag
        // as these are valid responses from the api.
        doNotReport = true
      }

      if (error?.errors?.find(err => err.status === 401) && isGQLV2) {
        doNotReport = true
        if (json?.data?.billing) {
          app.store.dispatch(doRedirectToBilling())
        } else {
          app.store.dispatch({ type: LOGOUT_PAGE })
        }
      }

      if (error?.errors?.find(err => err.status === 503) && isGQLV2) {
        doNotReport = true
        app.store.dispatch({ type: MAINTENANCE_PAGE })
      }

      if (doNotReport === false) {
        Bugsnag.notify(new Error(errorName), event => {
          // eslint-disable-next-line no-param-reassign
          event.groupingHash = event.errors[0].errorMessage
          // eslint-disable-next-line no-param-reassign
          event.severity = 'error'
          // eslint-disable-next-line no-param-reassign
          event.context = queryName

          event.addMetadata('metaData', {
            meta: {
              query,
              variables,
              requestId,
              status: response.status,
              responseErrors: response.json && response.json.errors, // don't send the actual response, just errors
            },
          })
          event.addMetadata('request', {
            url,
            method: 'POST',
            headers,
            body,
          })
        })
        // we already reported, so don't push it again to Bugsnag
        error.doNotReport = true
      }
      return reject(error)
    }
    // eslint-disable-next-line no-param-reassign
    response.requestId = requestId

    return resolve(response)
  })

const errorIs404 = error => {
  const { errors = [] } = error || {}
  const codes = uniq(errors.map(e => e.status))
  return codes.length === 1 && codes[0] === 404
}

// Returns a function that will execute a graphql query/mutation against the
// configured `url`. Does not do authorization checking or special 401 handling
export const callGraphQL = (url, _token, query, variables, requestId) => {
  const isQuery = query.trim().match(/^query\s/)
  const queryMatches = query.match(/^\s*((query)|(mutation))\s+([^\s(]+)/)
  const queryName = queryMatches && queryMatches[4]
  const retries = isQuery ? 3 : 0 // Only retry queries, not mutations
  const data = { query, _method: 'POST' }
  if (variables) data.variables = variables
  const body = JSON.stringify(data)
  const adHocRequestId = uuidV4()

  const headers = {
    'Content-Type': 'application/json',
    'X-Request-Id': requestId || adHocRequestId,
    'X-Client-Tab-Id': getTabId(),
    'X-Client-App-Version': getVersion(),
  }

  return postWithRetry(
    `${url}${queryName ? `?_${queryName}` : ''}`,
    headers,
    body,
    retries
  )
    .then(
      mapGraphQLResponse(
        url,
        headers,
        data,
        query,
        variables,
        requestId || adHocRequestId
      )
    )
    .catch(error => {
      // log it to make debugging easer since this error results in a redirect
      if (!errorIs404(error)) {
        // eslint-disable-next-line no-console
        console.error(JSON.stringify({ request: body, error }))
      }
      throw error
    })
}

// Main Entrypoint. Returns a function that will execute a graphql
// query/mutation against the configured `url`.
export const graphQLAPI = url => (token, query, variables, requestId) =>
  callGraphQL(url, token, query, variables, requestId)
