import { push } from 'redux-first-router'

import { basicFields as agentFields } from 'api/agents'
import { basicFields as groupFields } from 'api/groups'

import graphql from 'api/graphql'
import grooveAPI from 'api/groove'
import { basicFields as accessListFields } from 'api/access_lists'
import { basicFields as accountFields } from 'api/account'
import { basicFields as userFields } from 'ducks/currentUser/api'
import { basicFields as companyFields } from 'api/company'
import { selectCurrentUserHasFullAccess } from 'ducks/currentUser/selectors/base'

import { doBootstrapWidgetsAndSettings } from 'ducks/widgets/operations'

import { basicFields as billingFields } from 'ducks/billing/api'
import { APP_BILLING_EXPIRED } from 'ducks/billing/types'

import * as types from 'constants/action_types'
import * as pages from 'constants/pages'

import {
  selectCurrentPath as currentPathSelector,
  selectQueryParams,
  selectCurrentPage,
  selectIsInOnboarding,
} from 'selectors/location'
import {
  isBoostrappedSelector,
  oauthTokenSelector,
  selectReturnPath,
  fetchingStatusesSelector,
  selectAccountIsOnboardingCompleted,
} from 'selectors/app'
import {
  selectSelectedPivotId,
  selectSelectedRangeEndId,
} from 'selectors/ticket_list/base'

import bootstrap from 'util/bootstrap'
import { toQueryString } from 'util/params'
import { batchActions } from 'util/redux'

import { V2_VERIFICATION_PAGE } from 'subapps/onboarding/pages'
import { ONBOARDING_COMPLETE_SUCCESS } from 'subapps/onboarding/types'
import { AGENT_ACTIVE_OR_ARCHIVED_DATA_TABLE_QUERY_ID } from 'ducks/agents/constants'
import doRedirectToBilling from 'subapps/settings/actions/doRedirectToBilling'
import { doFetchAppBootstrapData } from 'ducks/bootstrap/actions'
import { doFetchNewComposeDrafts } from 'ducks/drafts2/operations/doFetchDrafts'
import doRedirectTo2faPageIfNeccesary from 'subapps/settings/actions/doRedirectTo2faPageIfNeccesary'
import { selectSearchEntityIdsByCurrentQueryId } from 'ducks/searches/selectors'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/selectCurrentUserGlobalId'
import { selectLegacyIsInboxBootstrapped } from 'ducks/bootstrap/selectors/selectLegacyIsInboxBootstrapped'
import { buildId } from 'util/globalId'

// Currently we're not using the ducks/request module to load our
// bootstrap data. This means we dont have access to all our normalizr magic
// This hack allows us to use that magic without using the full module which
// would required a bigger refactor
// Hack start
import {
  agent as agentEntity,
  team as teamEntity,
  folder as folderEntity,
  pinnedSearch as pinnedSearchEntity,
  session as sessionEntity,
  account as accountEntity,
} from 'ducks/entities/schema'
import entitiesActionModule from 'ducks/requests/modules/entitiesActionModule'
import searchesActionModule from 'ducks/requests/modules/searchesActionModule'
// Hack end

import { doToggleRightSidebar } from './doToggleRightSidebar'
import { doMarkFetchingStatus } from './doMarkFetchingStatus'

export { doToggleRightSidebar }

const bootstrapQuery = `
  query BootstrapQuery {
    access_lists {
      ${accessListFields}
    }
    agents(includeArchived: true) {
      ${agentFields}
    }
    groups {
      ${groupFields}
    }
    billing {
      ${billingFields}
    }
    account {
      ${accountFields}
    }
    user {
      ${userFields}
    }
    labels(perPage: 100) {
      records {
        id
        name
        color
        labelingsCount
      }
    }
    companyProfile {
      ${companyFields}
    }
  }
`

export function doFetchInboxBootstrapData() {
  return (dispatch, getState) => {
    dispatch(doFetchBootstrapData()).then(() => {
      const isInboxBootstrapped = selectLegacyIsInboxBootstrapped(getState())
      if (isInboxBootstrapped) return
      dispatch(
        // we need a second dispatch, because we need the app data to be set in the previous dispatch
        batchActions(
          { type: types.FETCHED_INBOX_DATA },
          doFetchNewComposeDrafts()
        )
      )
    })
  }
}

export function doFetchReportsBootstrapData() {
  return dispatch => {
    dispatch(batchActions(doFetchBootstrapData()))
  }
}

export function doFetchSettingsBootstrapData() {
  return dispatch => {
    dispatch(batchActions(doFetchBootstrapData()))
  }
}

export function doFetchKbBootstrapData() {
  return dispatch => {
    dispatch(batchActions(doFetchBootstrapData()))
  }
}

export function doFetchCrmBootstrapData() {
  return dispatch => {
    dispatch(batchActions(doFetchBootstrapData()))
  }
}

function doSetOnboardingCompletedIfRequired() {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const isInOnboarding = selectIsInOnboarding(state)
    const isOnboardingCompleted = selectAccountIsOnboardingCompleted(state)
    const hasFullAccess = selectCurrentUserHasFullAccess(state)

    if (!isInOnboarding && !isOnboardingCompleted && hasFullAccess) {
      return grooveAPI
        .post(token, 'v1/account/complete_onboarding', {})
        .then(() => {
          dispatch({
            type: ONBOARDING_COMPLETE_SUCCESS,
          })
        })
    }
    return null
  }
}

const handleExpireAccountFailures = error => (dispatch, getState) => {
  const { data } = error
  // If the user needs to confirm their email address
  if (data && data.user && !data.user.fullAccess) {
    dispatch({ type: V2_VERIFICATION_PAGE })
    return
  }

  // /v1/billing/account and /v1/me skips disabled account check
  // This means these endpoints "should" still return data even when
  // the account has expired. Data from both of these endpoints are
  // required for the /settings/billing page to work
  if (
    !data ||
    !data.billing ||
    !data.user ||
    !['expired', 'closed'].includes(data.billing.state)
  )
    return
  dispatch(
    batchActions(
      dispatch({
        type: APP_BILLING_EXPIRED,
        data,
      }),
      doMarkFetchingStatus('fetchAppData', false)
    )
  )
  // Redirect the person to the billing page
  const state = getState()
  if (selectCurrentPage(state) !== pages.SETTINGS_BILLING_PAGE) {
    dispatch(doRedirectToBilling({ withRedirect: true }))
  }
}

export function doFetchBootstrapData() {
  return (dispatch, getState) => {
    const state = getState()
    const isBootstrapped = isBoostrappedSelector(state)
    const isFetchingAppData = fetchingStatusesSelector(state).fetchAppData
    if (isBootstrapped || isFetchingAppData) return Promise.resolve()
    dispatch(doMarkFetchingStatus('fetchAppData', true))
    const token = oauthTokenSelector(state)
    const agentId = selectCurrentUserGlobalId(state)

    if (agentId) {
      dispatch(doFetchAppBootstrapData())
    }

    return graphql(token, bootstrapQuery)
      .then(res => {
        const data = res.json.data
        dispatch(
          batchActions(
            doUpdateAppData(data),
            doMarkFetchingStatus('fetchAppData', false),
            doSetOnboardingCompletedIfRequired(data),
            doBootstrapWidgetsAndSettings()
          )
        )
        if (!agentId) {
          dispatch(doFetchAppBootstrapData())
        }
        dispatch(doRedirectTo2faPageIfNeccesary())
        bootstrap.done()
      })
      .catch(error => {
        dispatch(handleExpireAccountFailures(error))
      })
  }
}

const bootstrapSchema = {
  agents: [agentEntity],
  groups: [teamEntity],
  folders: [folderEntity],
  currentUser: {
    pinned_searches: [pinnedSearchEntity],
  },
  session: sessionEntity,
  // In order for the sort in byQueryId to work we need access to the account preference
  // sort_conversation_by_collaborator_comment_enabled. Long term the plan would be to move
  // these preferences into the entity store. This is just a hack to get that one preference
  // in there.
  tempAccountPreference: accountEntity,
}

export function doUpdateAppData(payload, options = {}) {
  return (dispatch, getState) => {
    const state = getState()

    const host = window.location.host
    if (
      !options.preventRedirect &&
      (host.match('^beta.groovehq.com') || host.match('^app.groovehq.com'))
    ) {
      const token = oauthTokenSelector(state)
      const subdomain = payload.account.subdomain
      const destination = window.location.pathname
      localStorage.clear()
      window.location.href = `https://${subdomain}.groovehq.com/auth/callback?access_token=${token}&destination=${encodeURIComponent(
        destination
      )}`
    }

    // Decorate the action payload with some extra bits of (app) store state
    // that non-app reducers dont have access to.
    const path = currentPathSelector(state)
    const queryParams = selectQueryParams(state)

    let successAction = {
      type: types.UPDATE_APP_DATA,
      data: {
        accessLists: payload.access_lists,
        account: payload.account,
        currentUser: payload.user,
        folders: payload.folders,
        agents: payload.agents,
        groups: payload.groups,
        embeddableCards: payload.embeddableCards,
        labels: payload.labels && payload.labels.records,
        billing: payload.billing,
        creditCards: payload.creditCards,
        plans: payload.plans,
        path,
        queryParams,
        companyProfile: payload.companyProfile,
        session: {
          id: 'current',
          user: payload.user,
        },
        tempAccountPreference: {
          id: buildId('Account', payload?.account?.id),
          preferences: [
            Object.keys(payload?.account?.preferences || {}).map(key => {
              return {
                key,
                value: payload.account.preferences[key],
              }
            }),
          ],
        },
      },
    }
    successAction = entitiesActionModule(
      'SUCCESS',
      successAction,
      successAction.data,
      {
        normalizationSchema: bootstrapSchema,
      }
    )

    successAction = searchesActionModule(
      'SUCCESS',
      successAction,
      successAction.data.agents,
      {
        searches: {
          extractPagination: nodes => {
            return {
              type: 'agent',
              nodes,
              startCursor: 1,
              endCursor: 1,
              hasNextPage: false,
              hasPreviousPage: false,
              totalCount: nodes?.length || 0,
              totalPageCount: 1,
            }
          },
          cursor: 'all',
          queryId: AGENT_ACTIVE_OR_ARCHIVED_DATA_TABLE_QUERY_ID,
        },
      }
    )
    return dispatch(successAction)
  }
}

// Deprecated. Use Rudy (RFR) Links. ProTip: If you cant use a Link, and need
// to dispatch an action, the correct way would be to dispatch the associated
// _PAGE action
function doNavigate(path, params = null) {
  // eslint-disable-next-line
  console.warn(
    'WARN: doNavigate is deprecated. Use Links or dispatch a route action.'
  )
  let url = path
  if (params) {
    const query = toQueryString(params)
    if (query !== '') url = `${path}?${query}`
  }

  // RFR workaround - we dip into the RFR client API to ensure our history is
  // tracked correctly.
  push(url) // this will trigger a route action automagically...

  // While deprecated, this function still needs to return an action...
  return {
    type: types.DEPRECATED_CHANGE_PATH,
    data: {
      path,
    },
  }
}

export const doSaveReturnPath = path => {
  return {
    type: types.SAVE_RETURN_PATH,
    data: {
      path,
    },
  }
}

export const doClearReturnPath = () => {
  return {
    type: types.CLEAR_RETURN_PATH,
  }
}

export const doNavigateToReturnPath = (path = null) => (dispatch, getState) => {
  const returnPath = selectReturnPath(getState())
  return dispatch(
    batchActions(doNavigate(path || returnPath), doClearReturnPath())
  )
}

export function doChangeMailboxSwitcherVisibility({ visible }) {
  return {
    type: types.CHANGE_MAILBOX_SWITCHER_VISIBILITY,
    data: { visible },
  }
}

export function doToggleTicketSelection(id) {
  return {
    type: types.TOGGLE_TICKET_SELECTION,
    data: { id },
  }
}

export function doRangedTicketSelection(id, tickets = []) {
  return (dispatch, getState) => {
    const state = getState()
    const sortedMailboxTicketIds = tickets.map(t => t.id)
    const pivotId = selectSelectedPivotId(state)
    const rangeEndId = selectSelectedRangeEndId(state)

    return dispatch({
      type: types.RANGED_TICKET_SELECTION,
      data: { id, pivotId, rangeEndId, sortedMailboxTicketIds },
    })
  }
}

function doSelectManyTickets(ticketIds) {
  return {
    type: types.SELECT_TICKETS,
    data: { ticketIds },
  }
}

export function doSelectAllTickets() {
  return (dispatch, getState) => {
    const state = getState()
    const ticketIds = selectSearchEntityIdsByCurrentQueryId(state)
    return dispatch(doSelectManyTickets(ticketIds))
  }
}

export function doStartEditingTicketTitle(ticketId, currentTitle) {
  return {
    type: types.TICKET_TITLE_EDITING_START,
    data: { currentTitle, ticketId },
  }
}

export function doStopEditingTicketTitle(ticketId, currentTitle) {
  return {
    type: types.TICKET_TITLE_EDITING_STOP,
    data: { currentTitle, ticketId }, // informational
  }
}

export function doTicketTitleDraftChanged(title) {
  return {
    type: types.TICKET_TITLE_DRAFT_CHANGED,
    data: { title },
  }
}

export function doQueueEditorToolbarMessage(messageType, action, timeout) {
  return {
    type: types.QUEUE_EDITOR_TOOLBAR_MESSAGE,
    data: {
      messageType,
      action,
      timeout,
    },
  }
}

export function doUpdateAccessList(accessList) {
  return {
    type: types.UPDATE_ACCESS_LIST,
    data: accessList,
  }
}

export function doRemoveAccessList(mailboxId) {
  return {
    type: types.REMOVE_ACCESS_LIST,
    data: mailboxId,
  }
}

export function doListTopChanged(scrolledToTop) {
  return {
    type: types.LIST_SCROLL_TOP_CHANGED,
    data: { isTicketListScrolledToTop: scrolledToTop },
  }
}

export function doKeyChange(change = {}) {
  return {
    type: types.KEY_PRESSED_CHANGE,
    data: {
      change,
    },
  }
}

export function doUpdateAgentMention(mention) {
  return {
    type: types.UPDATE_AGENT_MENTION,
    data: mention,
  }
}

export function doUpdateMentions(agentId) {
  return {
    type: types.UPDATE_MENTIONS,
    data: agentId,
  }
}

export function doUpdateSelectedMentionSelectorAgentId(agentId) {
  return {
    type: types.UPDATE_SELECTED_MENTION_SELECTOR_AGENT_ID,
    data: agentId,
  }
}

export function doUpdateSuppressMentionSelector(bool) {
  return {
    type: types.UPDATE_SUPPRESS_MENTION_SELECTOR,
    data: bool,
  }
}

export function doNoop() {
  return {
    type: types.NOOP,
  }
}

export function doTogglePrintMode(printMode) {
  return {
    type: types.TOGGLE_PRINT_MODE,
    payload: { printMode },
  }
}

window.runNoop = () => {
  app.store.dispatch(doNoop())
}
