import Bugsnag from '@bugsnag/js'
import { v4 as uuidV4 } from 'uuid'
import config from 'config'
import { doUpdateAccessList, doRemoveAccessList } from 'actions/app'
// import { doLoadIncompleteSearch } from 'actions/search'
import {
  doRealtimeAiEvent,
  doRealtimeAiStreamingEvent,
} from 'ducks/ai/operations'
import { doRealtimeWalletTransactionEvent } from 'ducks/wallets/operations'
import { doRealtimeAccountEvent } from 'actions/account'
import { doFetchMailbox, doRemoveMailboxLocally } from 'ducks/mailboxes/actions'
import { doFetchAgent, doUpdateAgent, doRemoveAgent } from 'actions/agents'
import {
  doUpdateAgentTicketCollisionStatus,
  doRealtimeSetCurrentAgentCollisionStatus,
  doFetchCollisionStatuses,
  doAgentStartTicketTypingNotification,
} from 'ducks/collisions/actions'
import { startCollisionWindowWatching } from 'ducks/collisions/utils'
import { REFETCH_COLLISIONS_INTERVAL } from 'ducks/collisions/constants'

import * as types from 'constants/action_types'

import { selectAgents } from 'selectors/agents/base'
import metrics from 'util/metrics'
import WindowVisibility from 'util/window_visibility'
import debug, { logError, leaveBreadcrumb } from 'util/debug'
import realtime from 'util/realtime'

import { doRealtimeRoomEvent } from 'ducks/chat/actions/rooms'
import { doTryFetchAccountUsageOnboardingForOnboarding } from 'ducks/accountPreferences/operations'
import { selectFeatureBasedOnboardingWorkflowData } from 'subapps/onboarding/selectors'
import {
  selectCurrentChannelById,
  selectPendingChannelById,
} from 'ducks/channels/selectors'
import { DELETE_CONVERSATION_REALTIME } from 'ducks/tickets/actionTypes'
import { selectEntities } from 'ducks/entities/selectors'
import { selectLast100RequestIds } from 'ducks/requests/selectors'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import { doFetchTicket } from 'ducks/tickets/actions/doFetchTicket'
import { doSyncTicketSearches } from 'ducks/searches/operations/doSyncTicketSearches'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { CHANNEL_STATE } from 'ducks/mailboxes/constants'
import {
  entityAdditionalActionToActions,
  mergeEntityChanges,
} from 'ducks/entities/actionUtils'
import { buildConversationOptimistDeleteOptions } from 'ducks/tickets/utils/optimistic'
import { doFetchFolderCounts } from 'ducks/searches/operations/doFetchFolderCounts'
import { selectCurrentMailbox } from 'ducks/mailboxes/selectors/selectCurrentMailbox'
import { doRedirectToCollectionAndFolderById } from 'ducks/folders/operations/collections'
import { selectIsInInbox } from 'selectors/location'
import { AGENT_ROLE_AGENT } from 'ducks/agents/constants'
import { doMarkFetchingStatus } from './app/doMarkFetchingStatus'

export function doUpdateRealtimeStatus(subscribed) {
  return {
    type: types.UPDATE_REALTIME_STATUS,
    data: {
      subscribed,
    },
  }
}

const actionMap = {
  ticket: { action: doRealtimeTicketEvent },
  changeset: { action: doRealtimeChangesetEvent },
  mailbox: { action: doRealtimeMailboxEvent },
  user: { action: doRealtimeUserEvent },
  settings: { action: doRealtimeSettingsEvent },
  global: { action: doRealtimeGlobalEvent },
  mailbox_access_list: { action: doRealtimeMailboxAccessListEvent },
  agentAction: { action: doUpdateAgentTicketCollisionStatus },
  chat: { action: doRealtimeRoomEvent },
  account: { action: doRealtimeAccountEvent },
  realtime: {
    'ai.suggestion': {
      action: doRealtimeAiEvent,
    },
    'ai.streaming': {
      action: doRealtimeAiStreamingEvent,
    },
    'wallet.transaction': {
      action: doRealtimeWalletTransactionEvent,
    },
  },
}

const MAX_CONNECTION_ERRORS = 5
let connectionErrorCount = 0
let isFocused = true

async function startWatchingChannels(dispatch, channels) {
  try {
    await Promise.all(channels.map(channel => realtime.watch(channel)))
    dispatch(doUpdateRealtimeStatus(true))
    dispatch(doMarkFetchingStatus('subscribeRealtime', false))
  } catch (err) {
    leaveBreadcrumb('startWatchingChannels error', {
      error: err.message,
    })
    throw err
  }
}

// this function should only run once, because it's the socket that
// handles reconnections
export function doSubscribeToRealtime() {
  return async (dispatch, getState) => {
    const state = getState()
    const {
      app: { token },
    } = state
    leaveBreadcrumb('subscribing to realtime')

    try {
      dispatch(doMarkFetchingStatus('subscribeRealtime', true))
      const channels = await realtime.connect(
        token,
        message => watchHandler(message, dispatch),
        () => reconnectHandler('reconnect', dispatch, getState)
      )

      realtime.runIntervalWhenConnected(
        () => reconnectHandler('interval', dispatch, getState),
        REFETCH_COLLISIONS_INTERVAL,
        'reconnectHandler'
      )

      WindowVisibility.addEventListener('focus', () => {
        leaveBreadcrumb('window focus')
        isFocused = true
        reconnectHandler('focus', dispatch, getState)
      })

      WindowVisibility.addEventListener('blur', () => {
        leaveBreadcrumb('window blur')
        isFocused = false
      })
      startCollisionWindowWatching(getState)
      await Promise.all([
        startWatchingChannels(dispatch, channels),
        dispatch(doFetchCollisionStatuses()),
      ])
    } catch (err) {
      leaveBreadcrumb('post-subscribe error', {
        error: err,
      })
      handleConnectionError(err, dispatch)
    }
  }
}

function handleConnectionError(err, dispatch) {
  leaveBreadcrumb('connectionError', {
    error: err.message,
  })
  connectionErrorCount += 1
  // NOTE (jscheel): Make sure we don't get into endless loop of reconnecting.
  if (connectionErrorCount > MAX_CONNECTION_ERRORS) {
    // eslint-disable-next-line no-console
    console.error(`Error connecting to realtime: ${err}`)
    dispatch(doMarkFetchingStatus('subscribeRealtime', true))
  } else {
    setTimeout(() => {
      dispatch(doUpdateRealtimeStatus(false))
      dispatch(doMarkFetchingStatus('subscribeRealtime', false))
      // eslint-disable-next-line no-restricted-properties
    }, Math.round(Math.pow((20 + Math.random()) * connectionErrorCount, 2)))
  }
}

const NOOP_ACTION_TYPES = [
  'comment_template',
  'comment_template_category',
  // We dont need comment actions because we already get changeset notifications.
  'comment',
]

let lastMessageId
function watchHandler(message, dispatch) {
  const { meta } = message
  // if the message id is the same as the previous one, we are in double-subscribe mode
  // if so, skip the message, but log for metrics
  if (message.id && lastMessageId === message.id) {
    // only report for changeset messages
    if (meta && meta.type === 'changeset') {
      if (config.isDevelopment || config.isAlpha) {
        debug('duplicate realtime message', {
          messageId: message.id,
          lastMessageId,
        })
      }
      metrics.increment('realtime_web_changeset_message_duplicated')
    }
    return
  }
  lastMessageId = message.id

  let type
  let subtype

  // skip comment_template, they are unsupported and it's not an error
  if (meta && NOOP_ACTION_TYPES.includes(meta.type)) {
    return
  }
  if (meta && meta.type && actionMap[meta.type]) {
    type = meta.type

    if (meta.subtype && actionMap[meta.type][meta.subtype]) {
      subtype = meta.subtype
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn('Cannot map realtime message to action', { message })
    Bugsnag.notify(
      new Error('Cannot map realtime message to action'),
      event => {
        // eslint-disable-next-line no-param-reassign
        event.errors[0].errorClass = 'RealtimeActionMapError'
        // eslint-disable-next-line no-param-reassign
        event.errors[0].errorMessage = 'Cannot map realtime message to action'
        event.addMetadata('metaData', {
          meta: {
            ...message.meta,
            actionMapKeys: actionMap ? Object.keys(actionMap) : null,
            attemptedType: meta.type,
          },
        })
      }
    )
    return
  }

  if (subtype) {
    dispatch(actionMap[type][subtype].action(message))
  } else {
    dispatch(actionMap[type].action(message))
  }
}

async function reconnectHandler(reason, dispatch) {
  const realtimeWasConnected = realtime.isConnected()
  const uuid = uuidV4()
  leaveBreadcrumb('reconnectHandler:start', {
    uuid,
    reason,
    isFocused,
    isConnected: realtimeWasConnected,
    state: realtime.getState(),
  })
  if (!isFocused) return
  if (!realtimeWasConnected) return
  try {
    await Promise.all([
      dispatch(
        doFetchCollisionStatuses(uuid, () => {
          leaveBreadcrumb('reconnectHandler:success', {
            uuid,
            reason,
            realtimeWasConnected,
            isFocused,
            realtimeIsConnected: realtime.isConnected(),
            state: realtime.getState(),
          })
        })
      ),
    ])
    dispatch(doRealtimeSetCurrentAgentCollisionStatus())
  } catch (err) {
    const realtimeIsConnected = realtime.isConnected()
    const payload = {
      uuid,
      reason,
      realtimeWasConnected,
      realtimeIsConnected,
      isFocused,
      state: realtime.getState(),
    }
    leaveBreadcrumb('reconnectHandler:error', payload)
    // don't report if it failed because realtime disconnected
    // or because the window is unfocused, these issues are expected
    if (!isFocused || !realtimeIsConnected) return
    logError(err)
  }
}

function doRealtimeTicketEvent(message) {
  return async (dispatch, getState) => {
    const {
      meta: { request_id: requestId, action, account_ticket_number: ticketId },
    } = message
    if (config.isDevelopment || config.isAlpha) {
      debug('realtime ticket message', { message })
    }
    const state = getState()
    const requestIds = selectLast100RequestIds(state)
    // Skip the realtime update if we made the request
    if (requestIds.includes(requestId)) return false
    const entities = selectEntities(state)

    const { additionalActions } = buildConversationOptimistDeleteOptions(
      getState,
      ticketId
    )

    switch (action) {
      case 'destroy':
        dispatch({
          type: DELETE_CONVERSATION_REALTIME,
          transformedEntities: {
            conversation: {
              [ticketId]: null,
            },
          },
          ...mergeEntityChanges([
            ...entityAdditionalActionToActions('STARTED', additionalActions),
            {
              entities: {
                state: entities,
                current: {},
              },
            },
          ]),
          meta: {
            requestId,
            updateSearches: true,
          },
        })
        break
      default:
      // do nothing
    }

    return true
  }
}

function doRealtimeChangesetEvent(message) {
  return async (dispatch, getState) => {
    const {
      meta: {
        timestamp,
        request_id: requestId,
        account_ticket_number: inputTicketId,
        filter_diffs: ticketSearches,
      },
    } = message
    const ticketId = inputTicketId.toString()
    metrics.increment('realtime_web_changeset_message_received')
    if (config.isDevelopment || config.isAlpha) {
      debug('realtime changeset message', { message })
    }

    const state = getState()
    const ticket = selectCurrentConversationById(state, ticketId)
    const requestIds = selectLast100RequestIds(state)

    // Moving forward realtime updates will use the following algorithm
    // 1. Check if we made the request and ignore it if we did
    // 2. If the ticket has already been loaded in state,
    // 2.1 Simply reload it. The doFetch ticket will sync  the local ticket
    //     and searches state based on the updated ticket information.
    // 3. If the ticket is not currently loaded in state, then use the provided filter diffs to
    // 3.1 Update the counts for loaded and unloaded searches
    // 3.2 For any loaded searches the ticket also needs to be added/removed from the cursor
    //     entities based on the count being returned

    // 1. Skip the realtime update if we made the request
    if (requestIds.includes(requestId)) {
      return false
    }

    // 2.1 Simply reload it. The doFetch ticket will sync  the local ticket
    if (ticket) {
      dispatch(
        doFetchTicket({
          conversationId: ticketId,
          channelType: MAILBOX_CHANNEL_TYPE,
          options: {
            concurrency: {
              key: 'realtime',
            },
            updateSearches: true,
          },
        })
      )
    } else {
      // 3. If the ticket is not currently loaded in state, then use the provided filter diffs to
      dispatch(doSyncTicketSearches(ticketId, ticketSearches, timestamp))
    }
    return true
  }
}

// We were using mailbox.active from realtime mailbox to check whether the mailbox is activated
// but the mailbox's active status is showing by state now. Check both of them in case it's changed on backend again:
const isMailboxActiveOrSyncing = mailbox =>
  mailbox.active ||
  [CHANNEL_STATE.ACTIVE, CHANNEL_STATE.SYNCING].includes(mailbox.state)

function doRealtimeMailboxEvent(message) {
  return async (dispatch, getState) => {
    const {
      data: mailbox,
      meta: { action },
    } = message
    if (!mailbox.id) {
      // TODO (jscheel): Determine if we want to report this bugsnag.
      return
    }
    const state = getState()
    const onboardingWorkflowData = selectFeatureBasedOnboardingWorkflowData(
      state
    )
    switch (action) {
      case 'create':
        if (
          !selectCurrentChannelById(state, mailbox.id) &&
          !selectPendingChannelById(state, mailbox.id)
        ) {
          // The creation action isn't from the current user, so we need to fetch the mailbox and rebuild the menu
          await dispatch(
            doFetchMailbox(mailbox.id, { shouldRebuildMenu: true })
          )
          dispatch(doFetchFolderCounts({ channelType: MAILBOX_CHANNEL_TYPE }))
        } // Else: The mailbox is created by the current user, it will be fetched by doCreateMailbox action

        dispatch(
          doTryFetchAccountUsageOnboardingForOnboarding(
            onboardingWorkflowData.mailbox?.usageKey,
            {
              completed: isMailboxActiveOrSyncing(mailbox),
              completedEventName: 'onboarding connected mailbox',
              shouldSetFlag: true,
            }
          )
        )
        break
      case 'update':
        {
          await dispatch(
            doFetchMailbox(mailbox.id, { shouldRebuildMenu: true })
          )
          const isInInbox = selectIsInInbox(getState())
          if (isInInbox) {
            await dispatch(
              doFetchFolderCounts({ channelType: MAILBOX_CHANNEL_TYPE })
            )
            const currentMailbox = selectCurrentMailbox(getState())
            if (
              mailbox.id === currentMailbox?.id &&
              !currentMailbox.hasAccess
            ) {
              dispatch(
                doRedirectToCollectionAndFolderById(null, null, {
                  ignoreLast: true,
                  channelType: MAILBOX_CHANNEL_TYPE,
                })
              )
            }
          }

          // The first mailbox is converted from demo
          dispatch(
            doTryFetchAccountUsageOnboardingForOnboarding(
              onboardingWorkflowData.mailbox?.usageKey,
              {
                completed: isMailboxActiveOrSyncing(mailbox),
                completedEventName: 'onboarding connected mailbox',
                shouldSetFlag: true,
              }
            )
          )
        }
        break
      case 'destroy':
        dispatch(doRemoveMailboxLocally(mailbox.id))
        break
      default:
        // eslint-disable-next-line no-console
        console.warn('Unknown message action')
        break
    }
  }
}

function doRealtimeMailboxAccessListEvent(message) {
  return dispatch => {
    const {
      data: accessList,
      meta: { action, mailbox_id: mailboxId },
    } = message
    switch (action) {
      case 'create':
      case 'update':
        // NOTE (jscheel): Access lists are so basic that we don't need to fetch
        // them separately right now. For now, the reducer will simply create
        // a new access list if one does not exist yet.
        dispatch(
          doUpdateAccessList({
            mailboxId,
            agentIds: accessList.agent_ids,
          })
        )
        break
      case 'destroy':
        // NOTE (jscheel): Access lists do not have their own id right now, so
        // we search for them via their mailbox id.
        dispatch(doRemoveAccessList(mailboxId))
        break
      default:
        // eslint-disable-next-line no-console
        console.warn('Unknown message action')
        break
    }
  }
}

const agentIsMissing = (state, user) => {
  return (
    selectAgents(state)
      .map(agent => agent.id)
      .indexOf(user.id) === -1
  )
}

function doRealtimeUserEvent(message) {
  return (dispatch, getState) => {
    const {
      data: user,
      meta: { action, role },
    } = message
    if (!user.id) return
    const state = getState()
    switch (action) {
      case 'create':
        if (role === AGENT_ROLE_AGENT) {
          dispatch(doFetchAgent(user.id))
        }
        break
      case 'update':
        // NOTE (jscheel): Right now we only deal with agent events.
        if (role === AGENT_ROLE_AGENT) {
          if (user.archived) {
            dispatch(doRemoveAgent(user.id))
          } else if (agentIsMissing(state, user)) {
            dispatch(doFetchAgent(user.id))
          } else {
            dispatch(doUpdateAgent(user))
          }
        }
        break
      case 'destroy':
        dispatch(doRemoveAgent(user.id))
        break
      default:
        // eslint-disable-next-line no-console
        console.warn('Unknown message action', message)
        break
    }
  }
}

function doRealtimeSettingsEvent(message) {
  return () => {
    // eslint-disable-next-line no-console
    console.log(message)
  }
}

function doRealtimeGlobalEvent(message) {
  return () => {
    // eslint-disable-next-line no-console
    console.log(message)
  }
}

// This method gets imported in a crap load of places. Leaving it here for now for backward
// compatibility
export const doRealtimeAgentStartTicketTypingNotification = doAgentStartTicketTypingNotification
